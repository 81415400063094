<template>
  <div id="kh-recommendations" class="container mt-5 mb-5">
    <div v-cloak>
      <div class="row">
        <aside class="col-lg-3 d-none d-lg-block">
          <h2>Aihepiirit</h2>
          <ul class="subjectMatters">
            <li
              v-for="subjectmatter in subjectMatters" :key="subjectmatter.id"
              @click="subject(subjectmatter)"
              v-bind:class="{
                selected: subjectmatter.ealaId === filterValues.subject
              }"
            >{{ subjectmatter.aihepiiri }} ({{ subjectmatter.atikkelilkm }})</li>
          </ul>
        </aside>
        <article class="col-sm-12 col-lg-9">
          <header class>
            <h2>Suositukset ({{ countrec }})</h2>
            <p id="kh-text">
              Käypä hoito -suositukset ovat riippumattomia, tutkimusnäyttöön
              perustuvia kansallisia hoitosuosituksia.
            </p>
            <p id="kh-guide">
              Voit selata suosituksia aihepiireittäin valitsemalla aihepiirin
              valikosta, rajata suositusten otsikkolistaa kirjoittamalla nimen
              alkuosan tai käyttää sivun oikeassa yläreunassa olevaa hakua
              kaikkien sisältöjen etsimiseen.
            </p>
          </header>
          <div class="row filter-row">
            <div class="col col-12 col-lg-8">
              <input
                type="text"
                id="recFilter"
                @keyup="filter"
                v-model="filterValues.search"
                placeholder="Rajaa suosituksia"
              >
              <span class="fa fa-search"></span>
            </div>
            <div class="col col-12 col-lg-4">
              <select id="sortlist" @change="sort" v-model="filterValues.sortby" required>
                <option value disabled selected hidden>Järjestä</option>
                <option value="ascTitle">Aakkosjärjestyksessä</option>
                <option value="descTitle">Aakkosjärjestyksessä Ö-A</option>
                <option value="ascDate">Näytä uusin ensin</option>
                <option value="descDate">Näytä vanhin ensin</option>
              </select>
            </div>
            <div class="col col-12 d-block d-lg-none">
              <select id="subject" @change="sort" v-model="filterValues.subject" required>
                <option value="0" disabled selected hidden>Aihepiirit</option>
                <option value="0">Näytä kaikki</option>
                <option
                  v-for="subjectmatter in subjectMatters"
                  :key="subjectmatter.id"
                  :value="subjectmatter.ealaId"
                >
                  {{ subjectmatter.aihepiiri }} ({{
                  subjectmatter.atikkelilkm
                  }})
                </option>
              </select>
            </div>
          </div>
          <div class="row">
            <div v-show="filterValues.subject != 0" id="selectedSubject">
              <strong>
                {{ filterValues.selectedSubject }} ({{
                recommendations.length
                }})
              </strong>
              <a id="removeSubject" @click="removeSubject">Tyhjennä valinta</a>
            </div>
            <ul id="recommendationsList" v-bind:class="{ active: isActive }">
              <li
                v-for="recommendation in recommendations"
                :key="recommendation.id"
                class="row rec-listitem"
              >

         
                <div
                  v-if="
                    recommendation.tila !== 'voimassa' &&
                      recommendation.tila !== 'päivityksessä' &&
                      recommendation.tila !== 'päivitys päätetty tehdä'
                  "
                >
                  <div class="col rec-title-inactive">{{ recommendation.otsikko }}</div>
                  <div class="col rec-status-inactive">
                    <i class="fas fa-exclamation-circle"></i>
                    <span id="capitalize">
                         <div
                            v-if="
                            recommendation.korvattu === 'p'
                          "
                         >
                              <a
                                  :href="recommendation.historynix">
                                  {{ recommendation.tila }}
                               </a>
                        </div>
                        
                        <div
                            v-else-if="
                            recommendation.korvattu === 'k'
                          "
                         >  
                             <a
                                 :href="recommendation.historynix">
                                  {{ recommendation.selite }}
                             </a>
                        </div>
                        <div
                              v-else-if="
                              recommendation.korvattu === 'e'
                           "
                         >
                                {{ recommendation.tila }}
                      </div>  


                    </span>
                  </div>
                </div>


                <div v-else class="container">
                  <a class="row" :href="recommendation.nimi">
                    <div class="col-sm-9 rec-title">
                      {{ recommendation.otsikko }}
                      <span v-if="recommendation.uusisuositus === 'k'" class="kh-new-or-updated">UUSI</span>
                      <span v-if="recommendation.uusisuositus === 'p'" class="kh-new-or-updated">PÄIVITETTY</span>
                    </div>
                    <div
                      class="col-sm-3 julkaistu"
                    >Julkaistu: {{ formatDate(recommendation.sisaltopvm) }}</div>
                  </a>
                  <div class="row">
                    <div v-if="recommendation.LiitteetObj['Potilasversio']">
                      <a
                        class="col-2 liitteet"
                        :href="windowLocationOrigin +'/' +recommendation.LiitteetObj['Potilasversio']"
                      >
                        <i class="fas fa-angle-right"></i>Potilaalle
                      </a>
                    </div>
                    <div v-if="recommendation.LiitteetObj['Tiivistelmä']">
                      <a
                        class="col-2 liitteet"
                        :href="windowLocationOrigin + '/' +recommendation.LiitteetObj['Tiivistelmä']"
                      >
                        <i class="fas fa-angle-right"></i>Tiivistelmä
                      </a>
                    </div>
                    <div v-if="recommendation.LiitteetObj['PDF']">
                      <a
                        class="col-2 liitteet"
                        :href="recommendation.LiitteetObj['PDF']"
                        target="_blank"
                      >
                        <i class="fas fa-file-pdf"></i>
                        <span>PDF</span>
                      </a>
                    </div>
                  </div>
                </div>
              </li>
              <p v-if="recommendations == ''" id="not-found">
                <strong>Huom!</strong> Hakua vastaavia suosituksia ei löytynyt.
              </p>
            </ul>
          </div>
        </article>
      </div>
    </div>
  </div>
</template>

<script>
import { sortby } from "./libs/sortby";
import { getParam } from "./libs/getParam";

function sorrRecommendations(arr, sortkey) {
  switch (sortkey) {
    case "ascTitle":
      arr.sort(sortby("otsikko", false, a => a.toUpperCase()));
      break;
    case "descTitle":
      arr.sort(sortby("otsikko", true, a => a.toUpperCase()));
      break;
    case "ascDate":
      arr.sort(sortby("timestamp", true, parseInt));
      break;
    case "descDate":
      //arr.sort(sortby("timestamp", false, parseInt));
      //break;
      arr.sort(function(a, b) {
        const key = 'timestamp';
        if (a[key] === 0) {
            return 1;
        }
        if (b[key] === 0) {
            return -1;
        }
        if (a[key] === b[key]) {
            return 0;
        }
        return a[key] > b[key] ? 1 : -1;
      });
      break;
  }
  return arr;
}

function filterBySearch(arr, filterKey = "") {
  if (filterKey.length >= 1) {
    var filterBy = filterKey.toUpperCase();
    return arr.filter(
      recommendation =>
        recommendation.otsikko.toUpperCase().indexOf(filterBy) > -1
    );
  } else {
    return arr;
  }
}

function filterBySubject(arr, subjectKey) {
  if (subjectKey === 0 || subjectKey === "0" || subjectKey === "showAll") {
    history.replaceState(null, null, '/suositukset');
    return arr;
  }
  const newArr = [];
  arr.forEach(row => {
    row.Erikoisalat.forEach(eri => {
      if (eri.erikoisalaid === subjectKey) {
        newArr.push(row);
      }
    });
  });
  history.pushState(null, null, '?erikoisala=' + subjectKey);
  return newArr;
}

function countRecommendations(arr) {
  var counter = 0;
  arr.forEach(row => {
    if (row.tila === 'voimassa' || row.tila === 'päivityksessä') {
      counter++;
    }
  });
  return counter;
}

export default {
  data: function() {
    return {
      windowLocationOrigin: window.location.origin,
      recommendationsOrigin: [],
      recommendations: [],
      subjectMatters: [],
      rec: [],
      countrec: "",
      isActive: false,
      filterValues: {
        search: "",
        sortby: "",
        subject: 0,
        selectedSubject: ""
      }
    };
  },
  computed: {
    subjectTitle: function() {
      // console.log("HOI", this.filterValues);
      this.subjectMatters.forEach(row => {
        if (this.filterValues.subject === row.ealaId) {
          this.filterValues.selectedSubject = row.aihepiiri;

        }
      });
    }
  },

  methods: {
    setRecommendations: function(arr) {
      this.rec = arr;
      this.recommendations = arr;
      this.isActive = true;
      // this.countrec = arr.length;
      this.countrec = countRecommendations(arr);
    },
    setSubjectMatters: function(arr) {
      this.subjectMatters = arr;
    },
    formatDate: function(date) {
      const d = new Date(date);
      const month = d.toLocaleString("fi-fi", { month: "long" });
      var year = d.getFullYear();
      return month + " " + year;
    },
    show () {
     this.subjectTitle;
      let arr = [...this.rec];
      arr = filterBySubject(arr, this.filterValues.subject);
      arr = filterBySearch(arr, this.filterValues.search);
      arr = sorrRecommendations(arr, this.filterValues.sortby);
      this.recommendations = arr;
      this.countrec = countRecommendations(this.recommendations);
    },
    subject: function(data) {
      this.filterValues.subject = data.ealaId;
      this.show();
    },
    removeSubject: function() {
      this.filterValues.subject = 0;
      this.filterValues.selectedSubject = "";
      this.show();
    },
    filter: function() {
      this.show();
    },
    sort () {
      this.show();
    }
  },
  created () {
    // console.log("user data from parent component:");
    console.log(khdata); //prints out an empty string
    khdata.Suositukset.forEach(function(row, index) {
      // Muodostetaan timestamp arvo unix timestamp integer:inä jotta voidaan tehdä sorttaus
      if (row.tila === "voimassa" || row.tila === "päivityksessä" || row.tila === "päivitys päätetty tehdä") {
        row["timestamp"] = new Date(row.sisaltopvm).getTime();
      } else {
        row["timestamp"] = 0;
      }
      // Template tykkää enemmän että nää on objektissa
      if (row.Liitteet) {
        row.LiitteetObj = {};
        row.Liitteet.forEach(function(liiteRow, i) {
          row.LiitteetObj[liiteRow.title] = liiteRow.url;
        });
      }
    });


    this.setRecommendations(khdata["Suositukset"]);
    this.setSubjectMatters(khdata["Suositukset aihepiireittäin"]);

  const ealaid = getParam('erikoisala');
    if(ealaid !== false) {

      this.filterValues.subject = parseInt(ealaid);
      this.show();
    }

  }
};
</script>