import Vue from "vue";
import Recommendations from "./Suositus.vue";
import Potilaalle from "./Potilaalle.vue";
import Rekommendationer from "./Rekommendationer.vue";

Vue.config.productionTip = false;

function onrecpageload() {
  if (document.querySelector("#kh-recommendations")) {
    /* eslint-disable no-new */
    const vm = new Vue({
      el: "#kh-recommendations",
      template: "<Recommendations/>",
      components: { Recommendations }
    });
    window.KH = vm;
  }
  if (document.querySelector("#kh-forpatients")) {
    /* eslint-disable no-new */
    const vm = new Vue({
      el: "#kh-forpatients",
      template: "<Potilaalle/>",
      components: { Potilaalle }
    });
    window.KH = vm;
  }
  if (document.querySelector("#kh-rekommendationer")) {
    /* eslint-disable no-new */
    const vm = new Vue({
      el: "#kh-rekommendationer",
      template: "<Rekommendationer/>",
      components: { Rekommendationer }
    });
    window.KH = vm;
  }
}

window.addEventListener("load", onrecpageload);
