<template>
  <div id="kh-recommendations" class="foobar container mt-5 mb-5">
    <div v-cloak>
      <div class="row">
        <aside class="col-lg-3 d-none d-lg-block">
          <!-- <h2>Aihepiirit</h2>
          <ul class="subjectMatters">
            <li
              v-for="subjectmatter in subjectMatters"
              :key="subjectmatter.id"
              @click="setSubject(subjectmatter.id)"
              v-bind:class="{
                selected: subjectmatter.id === filterValues.subject,
              }"
            >
              {{ subjectmatter.title }} ({{ subjectmatter.count }})
            </li>
          </ul> -->
        </aside>
        <article class="col-sm-12 col-lg-9">
          <header class>
            <h2>Rekommendationer ({{ recommendations.length }})</h2>
          </header>
          <div id="kh-text"></div>
          <div class="row filter-row">
            <div class="col col-12 col-lg-8">
              <input
                type="text"
                id="recFilter"
                @keyup="filter"
                v-model="filterValues.search"
                placeholder="Begränsa listan på rekommendationer"
              />
              <span class="fa fa-search"></span>
            </div>
            <div class="col col-12 col-lg-4">
              <select
                id="sortlist"
                @change="sort"
                v-model="filterValues.sortby"
                required
              >
                <option value disabled selected hidden>Ordna</option>
                <option value="ascTitle">Alfabetisk ordning</option>
                <option value="descTitle">Alfabetisk ordning Ö-A</option>
                <option value="ascDate">Visa senaste först</option>
                <option value="descDate">Visa äldsta först</option>
              </select>
            </div>
            <!-- <div class="col col-12 d-block d-lg-none">
              <select
                id="subject"
                @change="sort"
                v-model="filterValues.subject"
                required
              >
                <option value="0" disabled selected hidden>Aihepiirit</option>
                <option value="0">Näytä kaikki</option>
                <option
                  v-for="subjectmatter in subjectMatters"
                  :key="subjectmatter.id"
                  :value="subjectmatter.id"
                >
                  {{ subjectmatter.title }} ({{ subjectmatter.count }})
                </option>
              </select>
            </div> -->
          </div>
          <div class="row">
            <div v-show="filterValues.subject != 0" id="selectedSubject">
              <strong>
                {{ filterValues.selectedSubject }} ({{
                  recommendations.length
                }})
              </strong>
              <a id="removeSubject" @click="removeSubject">Tyhjennä valinta</a>
            </div>
            <ul id="recommendationsList" v-bind:class="{ active: isActive }">
              <li
                v-for="recommendation in recommendations"
                :key="recommendation.id"
                class="row rec-listitem"
              >
                <div class="container">
                  <a class="row" :href="recommendation.id">
                    <div class="col-sm-9 rec-title">
                      {{ recommendation.title }}
                    </div>
                    <div class="col-sm-3 julkaistu">
                      Publicerad: {{ formatDate(recommendation.date) }}
                    </div>
                  </a>
                  <div class="row">
                    <div v-if="recommendation.pdf">
                      <a
                        class="col-2 liitteet"
                        :href="recommendation.pdf"
                        target="_blank"
                      >
                        <i class="fas fa-file-pdf"></i>
                        <span>PDF</span>
                      </a>
                    </div>
                  </div>
                </div>
              </li>
              <p v-if="recommendations == ''" id="not-found">
                <strong>Notera!</strong>Inga rekommendationer matchade din sökning.
              </p>
            </ul>
          </div>
        </article>
      </div>
    </div>
  </div>
</template>

<script>
import { sortby } from "./libs/sortby";

function findGetParameter(parameterName) {
    var result = null,
        tmp = [];
    location.search
        .substr(1)
        .split("&")
        .forEach(function (item) {
          tmp = item.split("=");
          if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
        });
    return result;
}

function createSubjectsList(items) {
  const subjectmatters = {};
  items.forEach(function(row, index) {
    if (!row.subjectmatters) {
      return;
    }
    row.subjectmatters.forEach(function(subjectRow, i) {
      if (typeof subjectmatters[subjectRow.id] === "undefined") {
        subjectmatters[subjectRow.id] = {
          title: subjectRow.title,
          id: subjectRow.id,
          count: 0
        };
      }
      subjectmatters[subjectRow.id].count++;
    });
  });
  const result = Object.keys(subjectmatters).map(function(key) {
    return subjectmatters[key];
  });
  result.sort(sortby("title", false, a => a.toUpperCase()));
  return result;
}

function sorrRecommendations(arr, sortkey) {
  switch (sortkey) {
    case "ascTitle":
      arr.sort(sortby("title", false, a => a.toUpperCase()));
      break;
    case "descTitle":
      arr.sort(sortby("title", true, a => a.toUpperCase()));
      break;
    case "ascDate":
      arr.sort(sortby("timestamp", true, parseInt));
      break;
    case "descDate":
      //arr.sort(sortby("timestamp", false, parseInt));
      //break;
      arr.sort(function(a, b) {
        const key = "timestamp";
        if (a[key] === 0) {
          return 1;
        }
        if (b[key] === 0) {
          return -1;
        }
        if (a[key] === b[key]) {
          return 0;
        }
        return a[key] > b[key] ? 1 : -1;
      });
      break;
  }
  return arr;
}

function filterBySearch(arr, filterQuery = "") {
  const filterKey = "title";
  if (filterQuery.length >= 1) {
    var query = filterQuery.toUpperCase();
    return arr.filter(
      recommendation =>
        recommendation[filterKey].toUpperCase().indexOf(query) > -1
    );
  } else {
    return arr;
  }
}

function filterBySubject(arr, subjectId) {
  const subjectListKey = "subjectmatters";
  const subjectKey = "id";
  if (subjectId === 0 || subjectId === "0" || subjectId === "showAll") {
      history.replaceState(null, null, '/potilaalle');
    return arr;
  }
  const newArr = [];
  arr.forEach(row => {
    row[subjectListKey].forEach(eri => {
      if (eri[subjectKey] === subjectId) {
        newArr.push(row);
      }
    });
  });
  history.pushState(null, null, '?erikoisala=' + subjectId);
  return newArr;
}

export default {
  data: function() {
    return {
      windowLocationOrigin: window.location.origin,
      recommendationsOrigin: [],
      recommendations: [],
      subjectMatters: [],
      countrec: "",
      isActive: false,
      filterValues: {
        search: "",
        sortby: "",
        subject: 0,
        selectedSubject: ""
      }
    };
  },
  computed: {
    subjectTitle: function() {
      this.subjectMatters.forEach(row => {
        if (this.filterValues.subject === row.id) {
          this.filterValues.selectedSubject = row.title;
        }
      });
    }
  },

  methods: {
    setRecommendations: function(arr) {
      this.rec = arr;
      this.recommendations = arr;
      this.isActive = true;
      this.countrec = arr.length;
    },
    setSubjectMatters: function(arr) {
      this.subjectMatters = arr;
    },
    formatDate: function(date) {
      const d = new Date(date);
      const month = d.toLocaleString("sv-sv", { month: "long" });
      var year = d.getFullYear();
      return month + " " + year;
    },
    show: function() {
      this.subjectTitle;
      let arr = [...this.rec];
      // arr = filterBySubject(arr, this.filterValues.subject);
      arr = filterBySearch(arr, this.filterValues.search);
      arr = sorrRecommendations(arr, this.filterValues.sortby);
      this.recommendations = arr;
    },
    setSubject: function(id) {
      this.filterValues.subject = id;
      this.show();
    },
    removeSubject: function() {
      this.filterValues.subject = 0;
      this.filterValues.selectedSubject = "";
      this.show();
    },
    filter: function() {
      this.show();
    },
    sort: function() {
      this.show();
    },
    xhr: function(callback) {
      const req = new XMLHttpRequest();
      req.overrideMimeType("application/json");
      req.open('GET', '/wp-json/wp/v2/pages?slug=rekommendationer', true);
      req.onload  = function() {
        const result = JSON.parse(req.responseText);
        callback(result);
        // do something with jsonResponse
      };
      req.send(null);
    },
  },
  created: function() {
    // http://www.local.kaypahoito.fi//wp-json/wp/v2/pages?slug=rekommendationer
    this.xhr(function(r) {
              console.log(r[0].content.rendered);
              document.getElementById('kh-text').innerHTML = r[0].content.rendered;
    });
    // console.log("user data from parent component:");
    console.log(khdata); //prints out an empty string
    khdata.items.forEach(function(row, index) {
      // Muodostetaan timestamp arvo unix timestamp integer:inä jotta voidaan tehdä sorttaus
      // if (
      //   row.tila === "voimassa" ||
      //   row.tila === "päivityksessä" ||
      //   row.tila === "päivitys päätetty tehdä"
      // ) {
        row["timestamp"] = new Date(row.date).getTime();
      // } else {
      //   row["timestamp"] = 0;
      // }
      // Template tykkää enemmän että nää on objektissa
      if (row.Liitteet) {
        row.LiitteetObj = {};
        row.Liitteet.forEach(function(liiteRow, i) {
          row.LiitteetObj[liiteRow.title] = liiteRow.url;
        });
      }
    });
    createSubjectsList(khdata.items);

    this.setRecommendations(khdata.items);
    this.setSubjectMatters(createSubjectsList(khdata.items));

    const ealaid = findGetParameter('erikoisala');
    if(ealaid !== null) {

      this.filterValues.subject = parseInt(ealaid);
      this.show();
    }
  }
};
</script>
