var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "foobar container mt-5 mb-5",
      attrs: { id: "kh-recommendations" },
    },
    [
      _c("div", {}, [
        _c("div", { staticClass: "row" }, [
          _c("aside", { staticClass: "col-lg-3 d-none d-lg-block" }),
          _vm._v(" "),
          _c("article", { staticClass: "col-sm-12 col-lg-9" }, [
            _c("header", {}, [
              _c("h2", [
                _vm._v(
                  "Rekommendationer (" +
                    _vm._s(_vm.recommendations.length) +
                    ")"
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { attrs: { id: "kh-text" } }),
            _vm._v(" "),
            _c("div", { staticClass: "row filter-row" }, [
              _c("div", { staticClass: "col col-12 col-lg-8" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.filterValues.search,
                      expression: "filterValues.search",
                    },
                  ],
                  attrs: {
                    type: "text",
                    id: "recFilter",
                    placeholder: "Begränsa listan på rekommendationer",
                  },
                  domProps: { value: _vm.filterValues.search },
                  on: {
                    keyup: _vm.filter,
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.filterValues, "search", $event.target.value)
                    },
                  },
                }),
                _vm._v(" "),
                _c("span", { staticClass: "fa fa-search" }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col col-12 col-lg-4" }, [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.filterValues.sortby,
                        expression: "filterValues.sortby",
                      },
                    ],
                    attrs: { id: "sortlist", required: "" },
                    on: {
                      change: [
                        function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.filterValues,
                            "sortby",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                        _vm.sort,
                      ],
                    },
                  },
                  [
                    _c(
                      "option",
                      {
                        attrs: {
                          value: "",
                          disabled: "",
                          selected: "",
                          hidden: "",
                        },
                      },
                      [_vm._v("Ordna")]
                    ),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "ascTitle" } }, [
                      _vm._v("Alfabetisk ordning"),
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "descTitle" } }, [
                      _vm._v("Alfabetisk ordning Ö-A"),
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "ascDate" } }, [
                      _vm._v("Visa senaste först"),
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "descDate" } }, [
                      _vm._v("Visa äldsta först"),
                    ]),
                  ]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.filterValues.subject != 0,
                      expression: "filterValues.subject != 0",
                    },
                  ],
                  attrs: { id: "selectedSubject" },
                },
                [
                  _c("strong", [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.filterValues.selectedSubject) +
                        " (" +
                        _vm._s(_vm.recommendations.length) +
                        ")\n            "
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      attrs: { id: "removeSubject" },
                      on: { click: _vm.removeSubject },
                    },
                    [_vm._v("Tyhjennä valinta")]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "ul",
                {
                  class: { active: _vm.isActive },
                  attrs: { id: "recommendationsList" },
                },
                [
                  _vm._l(_vm.recommendations, function (recommendation) {
                    return _c(
                      "li",
                      {
                        key: recommendation.id,
                        staticClass: "row rec-listitem",
                      },
                      [
                        _c("div", { staticClass: "container" }, [
                          _c(
                            "a",
                            {
                              staticClass: "row",
                              attrs: { href: recommendation.id },
                            },
                            [
                              _c("div", { staticClass: "col-sm-9 rec-title" }, [
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(recommendation.title) +
                                    "\n                  "
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-sm-3 julkaistu" }, [
                                _vm._v(
                                  "\n                    Publicerad: " +
                                    _vm._s(
                                      _vm.formatDate(recommendation.date)
                                    ) +
                                    "\n                  "
                                ),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "row" }, [
                            recommendation.pdf
                              ? _c("div", [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "col-2 liitteet",
                                      attrs: {
                                        href: recommendation.pdf,
                                        target: "_blank",
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "fas fa-file-pdf",
                                      }),
                                      _vm._v(" "),
                                      _c("span", [_vm._v("PDF")]),
                                    ]
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                        ]),
                      ]
                    )
                  }),
                  _vm._v(" "),
                  _vm.recommendations == ""
                    ? _c("p", { attrs: { id: "not-found" } }, [
                        _c("strong", [_vm._v("Notera!")]),
                        _vm._v(
                          "Inga rekommendationer matchade din sökning.\n            "
                        ),
                      ])
                    : _vm._e(),
                ],
                2
              ),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }