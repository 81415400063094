var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container mt-5 mb-5", attrs: { id: "kh-recommendations" } },
    [
      _c("div", {}, [
        _c("div", { staticClass: "row" }, [
          _c("aside", { staticClass: "col-lg-3 d-none d-lg-block" }, [
            _c("h2", [_vm._v("Aihepiirit")]),
            _vm._v(" "),
            _c(
              "ul",
              { staticClass: "subjectMatters" },
              _vm._l(_vm.subjectMatters, function (subjectmatter) {
                return _c(
                  "li",
                  {
                    key: subjectmatter.id,
                    class: {
                      selected:
                        subjectmatter.ealaId === _vm.filterValues.subject,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.subject(subjectmatter)
                      },
                    },
                  },
                  [
                    _vm._v(
                      _vm._s(subjectmatter.aihepiiri) +
                        " (" +
                        _vm._s(subjectmatter.atikkelilkm) +
                        ")"
                    ),
                  ]
                )
              }),
              0
            ),
          ]),
          _vm._v(" "),
          _c("article", { staticClass: "col-sm-12 col-lg-9" }, [
            _c("header", {}, [
              _c("h2", [_vm._v("Suositukset (" + _vm._s(_vm.countrec) + ")")]),
              _vm._v(" "),
              _c("p", { attrs: { id: "kh-text" } }, [
                _vm._v(
                  "\n            Käypä hoito -suositukset ovat riippumattomia, tutkimusnäyttöön\n            perustuvia kansallisia hoitosuosituksia.\n          "
                ),
              ]),
              _vm._v(" "),
              _c("p", { attrs: { id: "kh-guide" } }, [
                _vm._v(
                  "\n            Voit selata suosituksia aihepiireittäin valitsemalla aihepiirin\n            valikosta, rajata suositusten otsikkolistaa kirjoittamalla nimen\n            alkuosan tai käyttää sivun oikeassa yläreunassa olevaa hakua\n            kaikkien sisältöjen etsimiseen.\n          "
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row filter-row" }, [
              _c("div", { staticClass: "col col-12 col-lg-8" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.filterValues.search,
                      expression: "filterValues.search",
                    },
                  ],
                  attrs: {
                    type: "text",
                    id: "recFilter",
                    placeholder: "Rajaa suosituksia",
                  },
                  domProps: { value: _vm.filterValues.search },
                  on: {
                    keyup: _vm.filter,
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.filterValues, "search", $event.target.value)
                    },
                  },
                }),
                _vm._v(" "),
                _c("span", { staticClass: "fa fa-search" }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col col-12 col-lg-4" }, [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.filterValues.sortby,
                        expression: "filterValues.sortby",
                      },
                    ],
                    attrs: { id: "sortlist", required: "" },
                    on: {
                      change: [
                        function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.filterValues,
                            "sortby",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                        _vm.sort,
                      ],
                    },
                  },
                  [
                    _c(
                      "option",
                      {
                        attrs: {
                          value: "",
                          disabled: "",
                          selected: "",
                          hidden: "",
                        },
                      },
                      [_vm._v("Järjestä")]
                    ),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "ascTitle" } }, [
                      _vm._v("Aakkosjärjestyksessä"),
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "descTitle" } }, [
                      _vm._v("Aakkosjärjestyksessä Ö-A"),
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "ascDate" } }, [
                      _vm._v("Näytä uusin ensin"),
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "descDate" } }, [
                      _vm._v("Näytä vanhin ensin"),
                    ]),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col col-12 d-block d-lg-none" }, [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.filterValues.subject,
                        expression: "filterValues.subject",
                      },
                    ],
                    attrs: { id: "subject", required: "" },
                    on: {
                      change: [
                        function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.filterValues,
                            "subject",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                        _vm.sort,
                      ],
                    },
                  },
                  [
                    _c(
                      "option",
                      {
                        attrs: {
                          value: "0",
                          disabled: "",
                          selected: "",
                          hidden: "",
                        },
                      },
                      [_vm._v("Aihepiirit")]
                    ),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "0" } }, [
                      _vm._v("Näytä kaikki"),
                    ]),
                    _vm._v(" "),
                    _vm._l(_vm.subjectMatters, function (subjectmatter) {
                      return _c(
                        "option",
                        {
                          key: subjectmatter.id,
                          domProps: { value: subjectmatter.ealaId },
                        },
                        [
                          _vm._v(
                            "\n                " +
                              _vm._s(subjectmatter.aihepiiri) +
                              " (" +
                              _vm._s(subjectmatter.atikkelilkm) +
                              ")\n              "
                          ),
                        ]
                      )
                    }),
                  ],
                  2
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.filterValues.subject != 0,
                      expression: "filterValues.subject != 0",
                    },
                  ],
                  attrs: { id: "selectedSubject" },
                },
                [
                  _c("strong", [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.filterValues.selectedSubject) +
                        " (" +
                        _vm._s(_vm.recommendations.length) +
                        ")\n            "
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      attrs: { id: "removeSubject" },
                      on: { click: _vm.removeSubject },
                    },
                    [_vm._v("Tyhjennä valinta")]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "ul",
                {
                  class: { active: _vm.isActive },
                  attrs: { id: "recommendationsList" },
                },
                [
                  _vm._l(_vm.recommendations, function (recommendation) {
                    return _c(
                      "li",
                      {
                        key: recommendation.id,
                        staticClass: "row rec-listitem",
                      },
                      [
                        recommendation.tila !== "voimassa" &&
                        recommendation.tila !== "päivityksessä" &&
                        recommendation.tila !== "päivitys päätetty tehdä"
                          ? _c("div", [
                              _c(
                                "div",
                                { staticClass: "col rec-title-inactive" },
                                [_vm._v(_vm._s(recommendation.otsikko))]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "col rec-status-inactive" },
                                [
                                  _c("i", {
                                    staticClass: "fas fa-exclamation-circle",
                                  }),
                                  _vm._v(" "),
                                  _c("span", { attrs: { id: "capitalize" } }, [
                                    recommendation.korvattu === "p"
                                      ? _c("div", [
                                          _c(
                                            "a",
                                            {
                                              attrs: {
                                                href: recommendation.historynix,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                " +
                                                  _vm._s(recommendation.tila) +
                                                  "\n                             "
                                              ),
                                            ]
                                          ),
                                        ])
                                      : recommendation.korvattu === "k"
                                      ? _c("div", [
                                          _c(
                                            "a",
                                            {
                                              attrs: {
                                                href: recommendation.historynix,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                " +
                                                  _vm._s(
                                                    recommendation.selite
                                                  ) +
                                                  "\n                           "
                                              ),
                                            ]
                                          ),
                                        ])
                                      : recommendation.korvattu === "e"
                                      ? _c("div", [
                                          _vm._v(
                                            "\n                              " +
                                              _vm._s(recommendation.tila) +
                                              "\n                    "
                                          ),
                                        ])
                                      : _vm._e(),
                                  ]),
                                ]
                              ),
                            ])
                          : _c("div", { staticClass: "container" }, [
                              _c(
                                "a",
                                {
                                  staticClass: "row",
                                  attrs: { href: recommendation.nimi },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "col-sm-9 rec-title" },
                                    [
                                      _vm._v(
                                        "\n                    " +
                                          _vm._s(recommendation.otsikko) +
                                          "\n                    "
                                      ),
                                      recommendation.uusisuositus === "k"
                                        ? _c(
                                            "span",
                                            {
                                              staticClass: "kh-new-or-updated",
                                            },
                                            [_vm._v("UUSI")]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      recommendation.uusisuositus === "p"
                                        ? _c(
                                            "span",
                                            {
                                              staticClass: "kh-new-or-updated",
                                            },
                                            [_vm._v("PÄIVITETTY")]
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "col-sm-3 julkaistu" },
                                    [
                                      _vm._v(
                                        "Julkaistu: " +
                                          _vm._s(
                                            _vm.formatDate(
                                              recommendation.sisaltopvm
                                            )
                                          )
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "row" }, [
                                recommendation.LiitteetObj["Potilasversio"]
                                  ? _c("div", [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "col-2 liitteet",
                                          attrs: {
                                            href:
                                              _vm.windowLocationOrigin +
                                              "/" +
                                              recommendation.LiitteetObj[
                                                "Potilasversio"
                                              ],
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "fas fa-angle-right",
                                          }),
                                          _vm._v(
                                            "Potilaalle\n                    "
                                          ),
                                        ]
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                recommendation.LiitteetObj["Tiivistelmä"]
                                  ? _c("div", [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "col-2 liitteet",
                                          attrs: {
                                            href:
                                              _vm.windowLocationOrigin +
                                              "/" +
                                              recommendation.LiitteetObj[
                                                "Tiivistelmä"
                                              ],
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "fas fa-angle-right",
                                          }),
                                          _vm._v(
                                            "Tiivistelmä\n                    "
                                          ),
                                        ]
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                recommendation.LiitteetObj["PDF"]
                                  ? _c("div", [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "col-2 liitteet",
                                          attrs: {
                                            href: recommendation.LiitteetObj[
                                              "PDF"
                                            ],
                                            target: "_blank",
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "fas fa-file-pdf",
                                          }),
                                          _vm._v(" "),
                                          _c("span", [_vm._v("PDF")]),
                                        ]
                                      ),
                                    ])
                                  : _vm._e(),
                              ]),
                            ]),
                      ]
                    )
                  }),
                  _vm._v(" "),
                  _vm.recommendations == ""
                    ? _c("p", { attrs: { id: "not-found" } }, [
                        _c("strong", [_vm._v("Huom!")]),
                        _vm._v(
                          " Hakua vastaavia suosituksia ei löytynyt.\n            "
                        ),
                      ])
                    : _vm._e(),
                ],
                2
              ),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }