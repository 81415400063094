function getParam(key) {
    const array = window.location.search.slice(1).split('&');
    for (let index = 0; index < array.length; index += 1) {
      const element = array[index];
      const parts = element.split('=');
      if(key === parts[0]) {
        return parts[1];
      }
    }
    return false;
  }

  export { getParam };